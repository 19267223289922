import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/UserManagement",
    name: "home",
    component: () => import("../views/home/home.vue"),
    children: [
      {
        path: "/UserManagement",
        name: "UserManagement",
        component: () => import("../views/UserManagement/UserManagement.vue"),
      },
      {
        path: "/AccountSettings",
        name: "AccountSettings",
        component: () => import("../views/AccountSettings/AccountSettings.vue"),
      },
      {
        path: "/UserInformationSettings",
        name: "UserInformationSettings",
        component: () => import("../views/AccountSettings/UserInformationSettings.vue"),
      },
      {
        path: "/UserInformationSettingsData",
        name: "UserInformationSettingsData",
        component: () => import("../views/AccountSettings/UserInformationSettingsData.vue"),
      },
      {
        path: "/QRCode",
        name: "QRCode",
        component: () => import("../views/AccountSettings/QRCode.vue"),
      },
      //汇款管理
      {
        path: "/RemittanceBill",
        name: "RemittanceBill",
        component: () => import("../views/remittance/RemittanceBill.vue"),
      },
      //会员管理
      {
        path: "/member-configuration",
        name: "member-configuration",
        component: () => import("../views/member-management/member-configuration.vue"),
      },
      //会员统计
      {
        path: "/member-statistics",
        name: "member-statistics",
        component: () => import("../views/member-statistics/member-statistics.vue"),
      },
      //工单管理
      {
        path: "/workorder-management",
        name: "workorder-management",
        component: () => import("../views/workorder-management/workorder-management.vue"),
      },
      //加量包管理
      {
        path: "/commodity-management",
        name: "commodity-management",
        component: () => import("../views/commodity-management/commodity-management.vue"),
      },
      //推送配置
      {
        path: "/Push-Configuration",
        name: "Push-Configuration",
        component: () => import("../views/Push-Configuration/Push-Configuration.vue"),
      },
      //更新配置
      {
        path: "/Update-configuration",
        name: "Update-configuration",
        component: () => import("../views/Update-configuration/Update-configuration.vue"),
      },
      //优惠券管理
      {
        path: "/Coupon-Management",
        name: "Coupon-Management",
        component: () => import("../views/Coupon-Management/Coupon-Management.vue"),
      },
      //添加优惠券
      {
        path: "/add-coupon",
        name: "add-coupon",
        component: () => import("../views/Coupon-Management/add-coupon.vue"),
      },
      //编辑优惠券
      {
        path: "/compile-coupon",
        name: "compile-coupon",
        component: () => import("../views/Coupon-Management/compile-coupon.vue"),
      },
      //领用明细
      {
        path: "/receive-detail",
        name: "receive-detail",
        component: () => import("../views/Coupon-Management/receive-detail.vue"),
      },
      //优惠券审核
      {
        path: "/Coupon-audit",
        name: "Coupon-audit",
        component: () => import("../views/Coupon-audit/Coupon-audit.vue"),
      },
      //顾客列表
      {
        path: "/Customer-List",
        name: "Customer-List",
        component: () => import("../views/Customer-Management/Customer-List.vue"),
      }
      ,
      //加量包订单管理
      {
        path: "/Commodity-order",
        name: "Commodity-order",
        component: () => import("../views/order-management/Commodity-order.vue"),
      },
      //会员订单管理
      {
        path: "/Membership-order",
        name: "Membership-order",
        component: () => import("../views/order-management/Membership-order.vue"),
      },
      //交易订单管理
      {
        path: "/Trading-order",
        name: "Trading-order",
        component: () => import("../views/order-management/Trading-order.vue"),
      },
      //首页广告管理
      {
        path: "/homea-advertising",
        name: "homea-advertising",
        component: () => import("../views/advertising-management/homeadvertising.vue"),
      },

      //商家广告管理
      {
        path: "/merchant-advertising",
        name: "merchant-advertising",
        component: () => import("../views/advertising-management/merchantadvertising.vue"),
      },
      
    //提现管理
    {
      path: "/withdraw-deposit",
      name: "withdraw-deposit",
      component: () => import("../views/remittance/withdraw-deposit.vue"),
    },
      //提现管理
    {
      path: "/Device-management",
      name: "Device-management",
      component: () => import("../views/Device-management/Device-management.vue"),
    },
    //商场管理
  {
    path: "/MallManagement",
    name: "MallManagement",
    component: () => import("../views/MallManagement/MallManagement.vue"),
  }
  ,
  //商户信息
{
  path: "/Merchant-Information",
  name: "Merchant-Information",
  component: () => import("../views/MallManagement/Merchant-Information.vue"),
}
        
         
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
